.App {
  position: absolute;
  height: 100vh;
  left: 0;
  bottom: 0;
  top: 0;
}

.App-logo {
  height: 35vmin;
  pointer-events: none;
  margin-top: 3rem;
}

.App2 {
  position: absolute;
  min-height: 100vh;
  left: 0;
  top: 0;
}

.cardmain{
  margin-left: 8vw;
  padding-top: 4vmax;
  
}

.MuiGrid-item{
  margin-top: 2rem !important;
}

.stock-image {
  pointer-events: none;
  width: 100%;
}

.info-card{
  background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url('./splash.jpg');
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  width: 73vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.info-card2{
  background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url('./splash.jpg');
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  width: 73vw;
  align-items: center;
  justify-content: center;
  color: white;
}

.marginleft{
  margin-left: .5rem !important;
}

.heading{
  font-size: 6rem;
}

.medtext{
  font-size: 3rem;
}

.linktext{
  font-size: 1.7rem;
  text-align: left;
}

.bodytext{
  font-size: 1.5rem;
}

.App-link {
  color: #61dafb;
}

.ContactForm {
  background-color: #241637;
  color: #fff;
  position: absolute;
  right: 0;
  height: 100vh;
  width: 27vw;
  justify-content: center;
  align-items: center;
}

.content{
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 4vmax;
  align-items: center;
}

.contentcontact{
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 4vmax;
  padding-left: 2rem;
  padding-right: 2rem;
}

.MuiSelect-icon{
  color: #fff !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}
.MuiFormLabel-root{
  color: #fff !important;
  font-size: .8rem !important;
}
.MuiFormHelperText-root{
  color: #fff !important;
}
.MuiInputBase-input{
  color: #fff !important;
}

.MuiGrid-spacing-xs-1 {
  width: 100% !important;
  margin: -4px;
}

.footer {
  min-height: 7vh;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: right !important;
  margin-top: 2vmax;
}

.footertext {
  font-family: "Franklin Libre" sans-serif;
  font-weight: 100 !important;
  margin-right: 2.2rem !important;
}

p{
  font-size: 1rem;
  font-weight: 100;
  margin: .5rem !important;
  line-height: 1.5;
}

.spacer {
  padding: 1rem;
}

.gridItem{
  padding-top: 2rem;
  align-items: center;
  justify-content: center;
}

.gridItem2{
  padding-top: 2.3rem;
  line-height: 2;
  align-items: center;
  justify-content: center;
}

.pageItem{
  align-items: center;
  justify-content: center;
  bottom: 0;
  padding-bottom: 6rem;
}

.paragraph{
    padding-top: 2rem;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 5rem;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: #fff;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.loadingForm {
  text-align: center;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
}

.loadingOuter {
  height: 100%;
}

.loading-circle {
  color: white;
  margin: 0 auto;
  height: 80px !important;
  width: 80px !important;
  padding-bottom: 5rem;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent
}

/* Change the white to any color ;) */
@-webkit-keyframes autofill {
  to {
      color: #666;
      background: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
    -webkit-transition-delay: 99999s;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

@media screen and (min-width: 1024px) {
  .info-card{
    overflow: scroll;
  }
  .info-card2{
    overflow: scroll;
  }
  .ContactForm{
    overflow: scroll;
  }
}

@media screen and (max-width: 1024px) {

  .heading{
    font-size: 3rem;
  }
  
  .medtext{
    font-size: 2rem;
  }
  
  .bodytext{
    font-size: 1.5rem;
  }

  .pageItem {
    align-items: center;
    justify-content: center;
    bottom: 0;
    padding-top: 0rem;
    padding-bottom: 6rem;
  }

  .App-logo {
    height: 20vmin;
    pointer-events: none;
    padding-top: 1rem;
  }
  
  .App2 {
    position: relative;
    left: 0;
    top: 0;
  }

  .App {
    position: relative;
  }

  .info-card{
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url('./splash.jpg');
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    padding-bottom: 2rem;
    padding-top: 0rem;
    width: 100vw;
  }
  
  .info-card2{
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url('./splash.jpg');
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    align-items: center;
    justify-content: center;
    color: white;
    padding-bottom: 2rem;
    padding-top: 0rem;
    width: 100vw;
    
    height: auto;
  }

  .ContactForm {
    background-color: #241637;
    color: #fff;
    justify-content: center;
    align-items: center;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-top: 0rem;
    width: 100vw;
    height: auto;
  }
} 